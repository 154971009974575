import React from 'react'
import { ModernapparchitecsecStyles } from './Modernapparchitecsec.styles'
import Image from 'components/ImgOptimized'
import Keybenefitssec from 'components/Keybenefitssec/Keybenefitssec'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Videoplayer from 'components/Videoplayer/Videoplayer'

function Modernapparchitecsec(props) {
  const { ModernapparchitecProps } = props.ModernapparchitecData
  const caseQuoteProp = ModernapparchitecProps.caseQuoteProps

  const {
    data: {
      appdevediagram: {
        childImageSharp: { fluid: appdevediagram },
      },
    },
  } = props
  return (
    <ModernapparchitecsecStyles className="ModernapparchitecsecStyles">
      <div className="container">
        <div className="ModernAppSecTitle">
          <h2>{ModernapparchitecProps.Title}</h2>
        </div>
        <div className="AppArchitectureListRow">
          {ModernapparchitecProps.AppArchitecDetails.map(
            (AppArchitecProps, i) => {
              return (
                <div key={i} className="AppArchitecCol">
                  <div className="ListTitle">
                    <h4>{AppArchitecProps.Title}</h4>
                  </div>
                  <div className="list">
                    <ul>
                      {AppArchitecProps.AppArchitecList.map(
                        (AppArchitecListProps, i) => {
                          return <li key={i}>{AppArchitecListProps}</li>
                        }
                      )}
                    </ul>
                  </div>
                </div>
              )
            }
          )}
        </div>
        <div className="AppArchitecDetailsRow">
          <div className="ArchitectureDetailsLeft">
            <p>{ModernapparchitecProps.ArchitecLeftDetailsPara}</p>
          </div>
          <div className="ArchitectureDetailsRight">
            <p>{ModernapparchitecProps.ArchitecRightDetailsPara}</p>
            <ol className="architectureList">
              {ModernapparchitecProps.FleetarchitectureList.map(
                (architectureList, i) => {
                  return <li key={i}>{architectureList}</li>
                }
              )}
            </ol>
          </div>
        </div>
      </div>
      <div className="AppTestimonialSec">
        <div className="container">
          <div className="AppTestimonialBgimage">
            <div className="AppTestimonialContent">
              <div className="AppTestimonialWrap">
                <p>{ModernapparchitecProps.TestimonialContent}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AppDevelopmentSec">
        <div className="container">
          <div className="AppDeveContent">
            <p>{ModernapparchitecProps.AppDeveContentPara}</p>
            <div className="appVideoSection">
              <Videoplayer videoURL={ModernapparchitecProps.AppDeveVideoUrl} />
            </div>
            <p>{ModernapparchitecProps.AppDeveBottomPara}</p>
            <figure>
              <Image
                fluid={appdevediagram}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 805,
                }}
              />
              <div className="img-caption">
                {ModernapparchitecProps.mainImgcaption}
              </div>
            </figure>
            <p>{ModernapparchitecProps.AppDevediagramPara}</p>
          </div>
        </div>
      </div>
      <div className="KeybenefitsContainer">
        <Keybenefitssec KeyBenefitsdata={ModernapparchitecProps} {...props} />
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ModernapparchitecsecStyles>
  )
}

export default Modernapparchitecsec
