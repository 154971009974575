import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { iHealthProps } from 'components/Props/iHealth'
import Casebanner from 'components/Casebanner/Casebanner'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Ihealthresultsec from 'components/Ihealthresultsec/Ihealthresultsec'
import Dealingbigdatasec from 'components/Dealingbigdatasec/Dealingbigdatasec'
import Modernapparchitecsec from 'components/Modernapparchitecsec/Modernapparchitecsec'
import Fasterdevelopmentsec from 'components/Fasterdevelopmentsec/Fasterdevelopmentsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { xl, lg, md } from 'config/variables'

export const IHealthpage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #5c6d80;
          }
        }
      }
    }
  }
  .Mobileappscreensec {
    margin-top: 74px;
    ${lg(`
        margin-top:50px;
    `)}
    ${md(`
        margin-top:30px;
    `)}
  }
  .IhealthresultsecStyles {
    margin-bottom: 100px;
    ${xl(`
      margin-bottom: 80px;
    `)}
      ${lg(`
      margin-bottom: 60px;
    `)}
      ${md(`
      margin-bottom: 40px;
    `)}
  }
  .ModernapparchitecsecStyles {
    margin-bottom: 100px;
    ${xl(`
      margin-bottom: 80px;
    `)}
      ${lg(`
      margin-bottom: 60px;
    `)}
      ${md(`
      margin-bottom: 40px;
    `)}
  }
`

const Ihealth = props => {
  const { data } = props
  const { location } = props

  let screenListItem = [
    [
      props.data.ihealthAppScreen1.childImageSharp.fluid,
      props.data.ihealthAppScreen2.childImageSharp.fluid,
      props.data.ihealthAppScreen3.childImageSharp.fluid,
      props.data.ihealthAppScreen4.childImageSharp.fluid,
    ],
    [
      props.data.ihealthAppScreen5.childImageSharp.fluid,
      props.data.ihealthAppScreen6.childImageSharp.fluid,
      props.data.ihealthAppScreen7.childImageSharp.fluid,
    ],
    [
      props.data.ihealthAppScreen8.childImageSharp.fluid,
      props.data.ihealthAppScreen9.childImageSharp.fluid,
    ],
  ]
  const colDaigramImg =
    props.data.microservicesArchitecture.childImageSharp.fluid
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="iHealth"
        description="Simform built iHealth, a clinically validated health monitoring & fitness coaching app for iPhone, iPad, Apple Watch & web using lean product development approach."
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <IHealthpage>
        <Casebanner
          bannerdata={iHealthProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={iHealthProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Ihealthresultsec IhealthresultData={iHealthProps} />
          <Dealingbigdatasec
            dealingbigdata={iHealthProps}
            {...props}
            appScreenList={screenListItem}
            ShowMobileappscreenSec
            showDealingDiagram
          />
        </div>
        {showContent && (
          <>
            <Modernapparchitecsec
              ModernapparchitecData={iHealthProps}
              {...props}
            />
            <Fasterdevelopmentsec
              FasterdevelopmentData={iHealthProps.Fasterdevelopmentprops}
              {...props}
              colDaigramImg={colDaigramImg}
            />
            <Relatedcaselist relatedcaselistdata={iHealthProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={iHealthProps} />
          </>
        )}
      </IHealthpage>
    </Layout>
  )
}

export default Ihealth

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/ihealth-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    sentmapdeashboard: file(
      relativePath: { regex: "/ihealth-app-screen@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dealingdiagram: file(
      relativePath: { regex: "/ihealth-diagram-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    appdevediagram: file(relativePath: { regex: "/app-dev-diagram@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    KeybenefitsThumb: file(relativePath: { regex: "/our-goal-icon@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 285) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    microservicesArchitecture: file(
      relativePath: { regex: "/faster-development-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/fitcom-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/sentmap-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    ihealthAppScreen1: file(
      relativePath: { regex: "/ihealth-app-screen1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen2: file(
      relativePath: { regex: "/ihealth-app-screen2@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen3: file(
      relativePath: { regex: "/ihealth-app-screen3@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen4: file(
      relativePath: { regex: "/ihealth-app-screen4@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen5: file(
      relativePath: { regex: "/ihealth-app-screen5@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen6: file(
      relativePath: { regex: "/ihealth-app-screen6@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen7: file(
      relativePath: { regex: "/ihealth-app-screen7@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen8: file(
      relativePath: { regex: "/ihealth-app-screen8@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen9: file(
      relativePath: { regex: "/ihealth-app-screen9@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
