import React from 'react'
import { images } from 'config/images'

export const iHealthProps = {
  bannerProps: {
    heading: 'iHealth',
    bannerPara:
      'Clinically validated health monitoring and fitness coaching solution',
    BannerAlt: 'iHealth Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Health and fitness apps as a category grew by 330% in the last 4 years.
        To make the mobile apps more accurate, integration with fitness trackers
        to measure the performance is the next big market.
        <br /> <br />
        iHealth Tech is founded by celebrity fitness coach Scott Alexander. They
        build fitness apps for professional trainers and fitness enthusiasts.
        Cardioguru (iTrainU) is one-of-a-kind app where workouts are backed by
        serious science and performance is measured against many factors. It
        helps trainers keep accurate track of performance over time and against
        many factors.
      </>
    ),
  },
  IhealthresultProps: {
    videoURL: images.ihealthIntroVideo,
    heading: 'Result',
    maincontent:
      'We developed apps for the iPad, iPhone, Apple Watch and Web. After 4 years in R & D, with support from the EU Innovation Scheme, The University of Lincoln and the U.K. Government, we have a tested, accurate and innovative health and fitness apps on the App Store. Some of the highlights of the app are:',
    resultAppList: [
      {
        IconUrl: images.fitnessIcon,
        IconAlt: 'Fitness Coaching',
        shortDescription: 'Fitness coaching and motivational speeches',
      },
      {
        IconUrl: images.trackingIcon,
        IconAlt: 'Accurate tracking',
        shortDescription: 'Accurate tracking of your data',
      },
      {
        IconUrl: images.exerciseIcon,
        IconAlt: 'Exercise plan',
        shortDescription: 'Exercise plan and customized exercises',
      },
      {
        IconUrl: images.appleWatchIcon,
        IconAlt: 'Apple Watch',
        shortDescription: 'Apple watch app for better workouts',
      },
    ],
    resultvideoURL: images.ResultihealthVideo,
    // caseQuoteProps: {
    //   maintext: (
    //     <>
    //       <span>“Simform have gone above and beyond our expectations,</span>{' '}
    //       integrating seamlessly with our own team to deliver cutting edge
    //       technology in a timely and professional manner.”
    //     </>
    //   ),
    //   userImg: images.edna,
    //   userImg2x: images.edna2x,
    //   userName: 'Edna Schwartz',
    //   designation: 'Chief Executive Officer',
    // },
  },
  dealingbigdataProps: {
    heading: 'What app did. Features of the app. How we worked.',
    para: (
      <>
        Simform worked with iHealth to help them create deeper engagement with
        their members and ultimately become a digital business. The team took a
        lean product development approach and kept evolving the app by
        introducing new functionality over time to constantly excite users.{' '}
        <br /> <br />
        We used agile methods to understand iHealth’s users, processes, and
        technology before building and testing a prototype to simplify the
        online renewals experience, including implementing a Content Management
        System to create and manage workout content.
      </>
    ),

    bottomPara: (
      <>
        Cardioguru uses lots of fitness data points to generate custom workouts
        and monitor progress over time using analytics. Unlike other fitness
        apps the app was primarily focused at serious fitness enthusiasts. This
        meant understanding the world of fitness trainers and learning about
        different data points and importance of them. We worked closely with
        Scott and his team to understand how they train their clients and how
        each kind of workout is important.
      </>
    ),
    diagramDetails: (
      <>
        Our research showed that celebrity trainers wanted to see real time
        synced data that is broken down into: progress of the workout, HRM
        accuracy and %HR zones reached. iHealth apps connect with a variety of
        industry leading devices that transmit data via secure bluetooth
        network.
      </>
    ),
    mainImgcaption:
      'iHealth app components with backend and 3rd party services',
  },
  ModernapparchitecProps: {
    Title: 'Modern App Architecture For Modular App Development',
    AppArchitecDetails: [
      {
        Title: 'Scalable with changing requirement',
        AppArchitecList: [
          'Handles changing business requirements efficiently',
          'Allow replacing third-party components easily',
          'Encourages composition of small independent components',
          'Allows Adding UI to features later',
          'Provides strategy for cutting tech debt',
        ],
      },
      {
        Title: 'Maintainable project',
        AppArchitecList: [
          'Should provide tactics for fighting massive objects',
          'Limits the number of dependencies for an object',
          'Reduces the amount of boilerplate code',
          'Minimizes the number of different roles of components',
          'Defines how to store state',
          'Easily pass data between components',
        ],
      },
      {
        Title: 'Promote quality',
        AppArchitecList: [
          'Maximizes the amount of testable code',
          'Allows subbing asynchronous code to run tests synchronously',
          'Supports testing of UI',
        ],
      },
    ],
    ArchitecLeftDetailsPara: (
      <>
        Over the years, we have learned to focus on these factors when choosing
        the tech architecture for iOS apps. <br />
        <br />
        Modern apps need to support multiple presentation types for same view
        controllers, e.g., on iPhone you push a new view controller but on iPad
        you either embed it as a containment view controller or show it from
        popover. <br />
        <br />
        We want a simple data flow which can be debugged easily if there is a
        crash or if there’s an error. We don’t want to have to jump across
        multiple different objects, mutating the same shared resource because
        that’s always hard to find. If you could have unidirectional data flow,
        that would be the best because it would allow you to just put a break on
        one place and see what’s happening with your data. Some architectures
        allow us to do that.
      </>
    ),
    ArchitecRightDetailsPara: (
      <>The native approach to building apps has lots of issues: </>
    ),

    FleetarchitectureList: [
      'Unnecessary dependencies - one view controller doesn’t need to know about the other',
      'Poor reusability',
      'Spaghetti code in case your app needs different presentation mode - you end up with a lot of control flow.',
      'Testing is harder, your view controllers will have a lot of side-effects.',
      'Singletons are tempting as they make it easier to write your code.',
    ],
    TestimonialContent:
      'When choosing architecture for iOS apps the most popular ones are MVC and MVVM. MVC is a popular architecture from web technologies but we think it is not ideal for mobile apps. ',
    AppDeveContentPara:
      'Matured MVC architectures often face the struggles of massive view controllers. For instance, the one controller can become as big as 3,000 lines of code due to handling too many responsibilities: business logic, data manipulation, data verification, networking logic, routing logic, etc. Secondly, MVC architectures have a fragile update process with a lack of testing.  Since your view controller is tightly coupled with the view, it becomes difficult to test because you have to be very creative in mocking views and their life cycle, while writing the view controller’s code in such a way, that your business logic is separated as much as possible from the view layout code.',
    AppDeveVideoUrl: images.AppDeveVideo,
    AppDeveBottomPara: (
      <>
        Testability is a very beneficial thing to think about when designing
        architecture. It’s not even about the test itself but how architecture
        allows clearly defined boundaries. Tests allow developers to save time
        in finding issues in runtime specifically when issues arise because
        intricacies of class when adding new features or refactoring.
        <br />
        <br />
        This is a very polarizing issue because most agency developers are not
        allowed to write tests, given their business models. Clients might not
        be willing to pay for it because they don’t understand it. It’s a choice
        whether could is tested or not. If someone hires a builder to build
        their house, they don’t tell them how to do their job, they hire them
        because they consider the fact that they are good.
        <br />
        <br />
        We have had much success with MVVM which is the most popular
        architecture. This pattern is pretty good because you can test your view
        models. Without it, the view models could not contain zero UI kit, which
        means there could be no dependencies of having view controllers loading,
        and you could test all your business logic.
        <br />
        <br />
        That testability from MVC would have 30%, with MVVM you could get to 70%
        and you could have UI tests for the rest. The difference from MVC to
        MVVM is: View Controllers are thinner now; The data formatting logic is
        decoupled from the View Controllers; MVVM made our code testable.
        <br />
        <br />
        One problem we have found with MVVM is that view controllers become
        gargantuan because they’re doing too many things. Keyboard management,
        user input, data transformation, view allocation — which of these is
        really the purview of the view controller? Which should be delegated to
        other objects?
      </>
    ),
    mainImgcaption: 'MVVM + C based iPhone app architecture',
    AppDevediagramPara: (
      <>
        The solution is a variation of the MVVM pattern, called MVVM+C, which
        adds a coordinator. The idea of a coordinator is simple. In your
        application you probably have some flows, like registration flow, user
        settings flow, purchasing flow, etc. Every flow is managed by a
        coordinator. The role of the coordinator is to know which view
        controller to display at a certain time. <br /> <br />A coordinator is
        an object that bosses one or more view controllers around. Taking all of
        the driving logic out of your view controllers, and moving that stuff
        one layer up is gonna make your life a lot more awesome.
      </>
    ),
    KeyBenefitsprops: {
      title: 'To sum it up, coordinators',
      BenefitsList: [
        'create a well defined way to deal with navigation',
        'make your view controllers less massive by moving navigation logic away from them',
        'make your view controllers reusable in different contexts',
        'organize your application by use case scenarios',
      ],
    },
    // caseQuoteProps: {
    //   maintext: (
    //     <>
    //       <span>“Simform have gone above and beyond our expectations,</span>{' '}
    //       integrating seamlessly with our own team to deliver cutting edge
    //       technology in a timely and professional manner.”
    //     </>
    //   ),
    //   userImg: images.edna,
    //   userImg2x: images.edna2x,
    //   userName: 'Edna Schwartz',
    //   designation: 'Chief Executive Officer',
    // },
  },
  Fasterdevelopmentprops: {
    heading: 'Modular architecture for faster development',
    maintitle:
      'Modularization is the process of breaking a codebase down into small, focused, and shareable pieces, or modules.',
    mainpara: (
      <>
        Once we began looking at our large scale app architecture, we figured
        out very quickly that it would be beneficial to break down some pieces
        of the app which were used throughout most of our features. <br />{' '}
        <br />
        Apple has been going down this path of small, focused, shareable pieces
        via app extensions like messaging, today, and notifications for years.
        All of these shareable pieces are available for any developer to use
        with their apps, just as ours would be if we were to open source our
        module, for example.
      </>
    ),
    FasterdeveService: [
      {
        Img: images.compilationTimeIcon,
        para: 'Reduced app compilation time ',
        className: '',
      },
      {
        Img: images.startupTimeIcon,
        para: 'Reduced app startup time',
        className: 'light-peach',
      },
      {
        Img: images.cleanerIcon,
        para:
          'Cleaner and more succinct “self-documented” public interfaces for code, entirely independent of the rest of the app ',
        className: 'light-sky-blue',
      },
      {
        Img: images.xcodeIcon,
        para: 'Smaller Xcode project files',
        className: 'powder-blue',
      },
      {
        Img: images.establishedIcon,
        para: 'Established code ownership',
        className: 'pale-mauve-two',
      },
      {
        Img: images.teamMembersIcon,
        para: 'Easier onboarding of new team members',
        className: 'light-peach-five',
      },
    ],
    ServiceDetails: (
      <>
        We created modules like our networking layer, model layer, and shareable
        UI Components, and we’ve been reaping the benefits of reusability,
        reduced compile times, and succinct test suites for months. <br />
        <br />A simplified example of our architecture is shown below, where any
        single smaller piece could be easily imported into any new app or
        framework we create.
      </>
    ),
    mainImgcaption: 'Modular framework for flexible app development',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Fitcom',
        para: 'Custom app solutions for health, wellness & fitness industries',
        className: 'bluegrey',
        link: 'case-studies/fitcom/',
      },
      {
        title: 'SentMap',
        para: 'Sentiment based market analytics engine for portfolio managers',
        className: 'pale-blue',
        link: '/sentiment-market-analytics-engine/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
