import styled from 'styled-components'
import { metaFont, bodyFont, xl, lg, md, sm } from 'config/variables'
import { images } from 'config/images'

export const ModernapparchitecsecStyles = styled.div`
  .ModernAppSecTitle {
    margin-bottom: 68px;
    ${xl(`
       margin-bottom: 60px;
    `)}
    ${lg(`
       margin-bottom: 60px;
    `)}
    ${md(`
       margin-bottom: 40px;
    `)}
  }
  .AppArchitectureListRow {
    display: flex;
    border: solid 1px #e8e9f3;
    background-color: #ffffff80;
    margin-bottom: 80px;
    ${lg(`
       margin-bottom: 60px;
    `)}
    ${md(`
       flex-direction:column;
       margin-bottom: 40px;
    `)}
    .AppArchitecCol {
      flex: 0 1 33.33%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid rgba(194, 197, 212, 0.3);
      ${md(`
        border-right:none;
        border-bottom: 1px solid rgba(194, 197, 212, 0.3);
      `)}
      &:first-child {
        .ListTitle {
          color: #85c26a;
        }
      }
      &:nth-child(2) {
        .ListTitle {
          color: #f6a25f;
        }
      }
      &:last-child {
        border-right: none;
        border-bottom:none;
        .ListTitle {
          color: #63d5ff;
        }
      }

      .ListTitle {
        flex: 0 0 101px;
        border-bottom: 1px solid rgba(194, 197, 212, 0.3);
        text-align: center;
        padding: 20px 40px 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        ${xl(`
          padding:20px 35px 14px;
        `)}
        ${lg(`
          padding:17px 15px 10px;
          flex: 0 0 76px;
        `)}
         ${md(`
          padding:17px 40px 10px;
          flex: 0 0 76px;
        `)}
        h4 {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 0px;
          ${lg(`
            font-size:16px;
            line-height:22px;
          `)}
        }
      }
      .list {
        flex: 1 1 auto;
        padding: 17px 40px 27px;
        ${xl(`
          padding: 17px 20px 27px;
        `)}
         ${lg(`
          padding: 11px 11px 9px;
        `)}
        ${md(`
            padding: 11px 15px 22px;
        `)}
        ul {
          padding-left: 20px;
          li {
            font-size: 18px;
            line-height: 24px;
            color: #3d3d3d;
            margin-bottom: 24px;
            ${lg(`
              font-size: 14px;
              line-height:20px;
            `)}
            ${lg(`
              margin-bottom:15px;
            `)}
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .AppArchitecDetailsRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    ${lg(`
        flex-direction: column;
      `)}
      ${md(`
        margin-bottom:25px;
      `)}
    .ArchitectureDetailsLeft {
      flex: 1 1 50%;
      padding: 0 40px 0 0;
      ${xl(`
       padding: 0 25px 0 0;
      `)}
      ${lg(`
       padding: 0 0px 0 0;
      `)}
    }
    .ArchitectureDetailsRight {
      flex: 1 1 50%;
      padding: 0 0 0 40px;
      ${xl(`
        padding: 0 0 0 25px;
      `)}
      ${lg(`
        padding: 20px 0 0 0;
      `)}
     ${md(`
        padding: 6px 0 0 0;
      `)}
    }
    .architectureList {
      padding: 0px;
      margin: 0px;
      counter-reset: item;
      list-style-type: none;
      ${lg(`
        padding: 20px 0 0 0;
      `)}
       ${md(`
        padding: 10px 0 0 0;
      `)}
      li {
        display: block;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.4px;
        font-weight: bold;
        font-family: ${metaFont};
        padding: 0 0 20px 68px;
        margin-bottom: 20px;
        position: relative;
        border-bottom: solid 2px #d8d8d8;
        ${lg(`
            padding: 0 0 18px 68px;
            margin-bottom: 18px;
        `)}
        ${md(`
            padding: 0 0 16px 40px;
            font-size:16px;
            line-height:26px;
            margin-bottom: 16px;
        `)}
        &:last-child {
          border-bottom: none;
          margin-bottom: 0px;
        }
        &:before {
          content: counter(item, decimal-leading-zero) ' ';
          counter-increment: item;
          left: 0;
          top: 0px;
          font-size: 22px;
          font-weight: 500;
          color: #85c26a;
          font-family: ${bodyFont};
          position: absolute;
          ${md(`
             font-size: 18px;
        `)}
        }
      }
    }
  }
  .AppTestimonialSec {
    background:#110f11 ;
    margin-bottom: 70px;
    ${lg(`
      margin-bottom: 60px;
    `)}
    ${md(`
      margin-bottom: 40px;
    `)}
     
    .AppTestimonialBgimage {
      padding: 155px 0 136px;
      background:url(${images.ihealthMobileAppsGif}) no-repeat right  top 20px;
      background-size: 520px;
    ${lg(`
      background-size: 400px;
      padding: 112px 0 109px;
    `)}
    ${md(`
      padding: 88px 0 80px;
      background-position:right 20px top 25px;
      background-size: 380px;
    `)}
     ${sm(`
      padding: 118px 0 50px;
      background-position:right top 25px;
      background-size: 380px;
    `)}
    }
    .AppTestimonialContent {
      max-width: 867px;
      margin: 0 auto;
      
      ${xl(`
        max-width: 100%;
      `)}

      .AppTestimonialWrap {
        max-width: 485px;
        padding-left: 22px;
        position: relative;
        z-index: 10;
        ${xl(`
          max-width: 541px;
          padding-left: 78px;
        `)}
        ${lg(`
          max-width: 396px;
          padding-left: 49px;
        `)}
        ${md(`
          max-width: 320px;
          padding-left: 40px;
        `)}
        ${sm(`
          max-width: 232px;
          padding-left: 40px;
        `)}
        &::after {
          content: '';
          position: absolute;
          background: url(${images.quoteIcon});
          top: -50px;
          left: -60px;
          z-index: 1;
          width: 95px;
          height: 68px;
          ${xl(`
            left:0px;
          `)}
          ${lg(`
            left:-10px;
            top:-35px;
            width: 70px;
            height: 50px;
            background-size:cover;
          `)}
          ${lg(`
            left:0px;
            top:-25px;
            width: 36px;
            height: 26px;
          `)}
        }
        p {
          color: #fff;
          position: relative;
          z-index: 10;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.34px;
          font-family: ${metaFont};
          margin-bottom: 0px;
          ${lg(`
            font-size:18px;
            line-height:27px;
            letter-spacing: -0.26px;
          `)}
          ${md(`
            font-size:14px;
            line-height:27px;
            letter-spacing: -0.2px;
          `)}
        }
      }
    }
  }

  .AppDevelopmentSec {
    margin-bottom: 73px;
    ${lg(`
         margin-bottom: 64px;
     `)}
     ${md(`
         margin-bottom: 40px;
     `)}
    .AppDeveContent {
      max-width: 867px;
      margin: 0 auto;
      .appVideoSection {
        margin: 74px 0 70px;
        ${lg(`
           margin: 64px 0 55px;
        `)}
        ${md(`
           margin: 40px 0 40px;
        `)}
        video {
          width:100%;
        }
      }
      figure {
        margin: 74px 0 70px;
        ${lg(`
           margin: 64px 0 55px;
        `)}
        ${md(`
           margin: 40px 0 40px;
        `)}
      }
    }
  }
  .KeybenefitsContainer {
    max-width: 1148px;
    margin: 0 auto;

    .KeybenefitsMainsec {
      margin-bottom: 0px;
      .KeybenefitsSec {
        .KeybenefitsSec-blk {
          ${lg(`
           padding:35px 10px 36px 20px;
        `)}
         ${md(`
            padding:30px 24px;
          `)}
          .KeybenefitsDetails {
            ${lg(`
                  padding-left: 35px;
            `)}
             ${md(`
                  padding-left: 0px;
            `)}
         
            .benefitsList {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }
`
